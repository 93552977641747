import React from 'react';
import PropTypes from 'prop-types';

import Numeral from 'numeral';

const DeliveryWarning = ({subtotal, minimumOrderAmount}) => {
  if (subtotal < minimumOrderAmount) {
    return (
        <div className="row pt-3">
          <div className="col-12 text-left col-cart-angkor alert alert-warning">
            <small>Your order does not qualify for delivery. The minimum delivery order amount is: {Numeral(minimumOrderAmount).format("$0,0.00")}</small>
          </div>
        </div>
      )
  } else {
      return null;
  }

};

DeliveryWarning.propTypes = {
    subtotal: PropTypes.number.isRequired,
    minimumOrderAmount: PropTypes.number.isRequired,
};

DeliveryWarning.defaultProps = {
    subtotal: 0,
    minimumOrderAmount: 0
};

export default DeliveryWarning;