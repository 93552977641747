import moment from 'moment';

export const getOpeningHourRange = (restaurant, asOfDate, businessHours, holidayHours=[], onlineOrderStart, onlineOrderEnd) => {
  let startAt = undefined;
  let endAt = undefined;
  let openTime = undefined;
  let closeTime = undefined;
  let isOpen = false;
  if (asOfDate.day() === 0) {
    openTime = moment(businessHours.sunday.open_time, restaurant.settings.time_format);
    closeTime = moment(businessHours.sunday.close_time, restaurant.settings.time_format);
    isOpen = businessHours.sunday.is_open;
  } else if (asOfDate.day() === 1) {
    openTime = moment(businessHours.monday.open_time, restaurant.settings.time_format);
    closeTime = moment(businessHours.sunday.close_time, restaurant.settings.time_format);
    isOpen = businessHours.monday.is_open;
  } else if (asOfDate.day() === 2) {
    openTime = moment(businessHours.tuesday.open_time, restaurant.settings.time_format);
    closeTime = moment(businessHours.tuesday.close_time, restaurant.settings.time_format);
    isOpen = businessHours.tuesday.is_open;
  } else if (asOfDate.day() === 3) {
    openTime = moment(businessHours.wednesday.open_time, restaurant.settings.time_format);
    closeTime = moment(businessHours.wednesday.close_time, restaurant.settings.time_format);
    isOpen = businessHours.wednesday.is_open;
  } else if (asOfDate.day() === 4) {
    openTime = moment(businessHours.thursday.open_time, restaurant.settings.time_format);
    closeTime = moment(businessHours.thursday.close_time, restaurant.settings.time_format);
    isOpen = businessHours.thursday.is_open;
  } else if (asOfDate.day() === 5) {
    openTime = moment(businessHours.friday.open_time, restaurant.settings.time_format);
    closeTime = moment(businessHours.friday.close_time, restaurant.settings.time_format);
    isOpen = businessHours.friday.is_open;
  } else if (asOfDate.day() === 6) {
    openTime = moment(businessHours.saturday.open_time, restaurant.settings.time_format);
    closeTime = moment(businessHours.saturday.close_time, restaurant.settings.time_format);
    isOpen = businessHours.saturday.is_open;
  }
  const filteredHoildayHours = holidayHours.filter(holidayHour => moment(holidayHour.date).isSame(asOfDate, 'day'));
  if (filteredHoildayHours.length > 0) {
    openTime = moment(filteredHoildayHours[0].open_time, restaurant.settings.time_format);
    closeTime = moment(filteredHoildayHours[0].close_time, restaurant.settings.time_format);
    isOpen = filteredHoildayHours[0].is_open;
  }

  startAt = moment(asOfDate)
    .startOf("day")
    .add(openTime.hours(), "hours")
    .add(openTime.minutes(), "minutes");

  if (onlineOrderStart) {
    startAt = startAt.add(onlineOrderStart, "minutes")
  }

  endAt = moment(asOfDate)
    .startOf("day")
    .add(closeTime.hours(), "hours")
    .add(closeTime.minutes(), "minutes");

  if (onlineOrderEnd) {
    endAt = endAt.subtract(onlineOrderEnd, "minutes")
  }

  return {
    startAt: startAt,
    endAt: endAt,
    isOpen: isOpen
  }
}
